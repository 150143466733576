import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="Hero404 bats404">
  <div className="HeroGroup">
  <h1>Swing and a Miss</h1>
    <p>Unfortunately, the page you are looking for cannot be found.</p>
    <Link className="btn" to="/">Let's go home</Link>
  </div></div>
  </Layout>
)

export default NotFoundPage
